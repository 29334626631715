import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const PowerWashing = () => {
	const TheTitle = "Power Washing Services"
	const TheDescription = "Driveways, Sidewalks, Retaining walls, pavers, and railings; just a few of the surfaces that need attention to truly polish up your property. Using a high pressured water stream we can quickly clean off mud, mold, algae, moss, paint and other hard to remove materials that cause discolouration or even potentially damage your hardscaping surfaces."
	const TheCanonical = "https://gardenescape.ca/services/power-washing/"

	return (
			<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
				<div className="serviceGrid">
					<StaticImage
						className="serviceGrid_boxA"
						src="../../images/home/leaves.png"
						alt="background placeholder"
						layout="fullWidth" />
					<div className="serviceGrid_boxB">
						<h1>{TheTitle}</h1>
						<p className="serviceGrid_boxB_text">25% off your first mow</p>
						<img
							src={MiniLawnChair}
							width="75px"
							height="75px"
							alt="garden escape mini logo" />
					</div>
				</div>
				<div className="mainWrapper my-16">
			<h2>WASH AWAY THE WINTER</h2>
			<p>Driveways, Sidewalks, Retaining walls, pavers, and railings; just a few of the surfaces that need attention to truly polish up your property. Using a high pressured water stream we can quickly clean off mud, mold, algae, moss, paint and other hard to remove materials that cause discolouration or even potentially damage your hardscaping surfaces.</p>
			<h2>WHEN DO I POWERWASH?</h2>
			<p>Best done in the spring but anytime is a great time to really put the polish on your property!</p>
			<h2>HOW DO I PROGRESS?</h2>
			<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>
			<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
			<p>We are happy to help you with all things related to beautifying your property, some other major services we offer are; Regular lawn mow, fertilizing, Seasonal Yard cleanup, Hedge and Shrub pruning, Aeration and Power Raking, and Junk Removal.</p>
				</div>
		</Layout>
	)
}

export default PowerWashing